<template>
  <div>
    <!-- dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      @click:outside="close"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Modifier {{ item.label }}</span>
        </v-card-title>
        <v-alert
          v-model="isAlertVisible"
          color="error"
          text
          transition="slide-y-transition"
        >
          {{ message }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForm"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="item.label"
                    type="text"
                    label="Libellé"
                    :rules="[rules.label]"
                  ></v-text-field>
                  <v-file-input
                    v-model="field.image"
                    show-size
                    counter
                    label="Logo"
                    @change="upload"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="close"
            >
              Annuler
            </v-btn>
            <v-btn
              :disabled="isDialogVisible"
              :loading="isDialogVisible"
              color="success"
              type="submit"
            >
              Modifier
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, ref, onMounted,
} from '@vue/composition-api'
import { mdiCalendar, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'OperatorFormDialog',

  model: {
    prop: 'operator',
    event: 'update:object',
  },

  emits: ['clickout', 'submitted'],

  props: {
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-type-constructor
    dialog: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    operator: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const isPasswordVisible = ref(false)
    const isPasswordConfirmationVisible = ref(false)
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)
    const form = ref(null)
    const message = ref('')
    const isDialogVisible = ref(false)
    const errors = ref('')
    const isAlertVisible = ref(false)
    const rules = {
      label: true,
    }
    const field = {
      image: null,
      path: null,
    }

    const instance = getCurrentInstance()

    const close = () => {
      isAlertVisible.value = false
      Object.keys(rules).forEach(key => {
        rules[key] = true
      })
      instance.emit('clickout')
      console.log(props.operator)
    }

    const item = computed({
      get: () => props.operator,
      set: value => emit('update:object', value),
    })

    const submitForm = () => {
      isAlertVisible.value = false
      isDialogVisible.value = true
      instance.emit('submitted')
      // eslint-disable-next-line no-param-reassign
      props.operator.logo_path = field.path
      store.dispatch('operator/updateOperator', props.operator).then(() => {
        isDialogVisible.value = false
        close()
      }).catch(error => {
        form.value.validate()
        message.value = error.response.data.message
        errors.value = error.response.data.errors
        if (error.response.data.errors !== undefined) {
          rules.label = error.response.data.errors.label !== undefined ? error.response.data.errors.label[0] : true
        }
        isDialogVisible.value = false
        isAlertVisible.value = true
      })
      instance.emit('submitted')
    }

    const upload = event => {
      const formData = new FormData()
      formData.append('image', event)
      store.dispatch('upload/uploadFile', { model: 'operator', field: formData }).then(() => {
        field.path = store.state.upload.file.chemin
      })
    }

    onMounted(() => {
      //initForm()
    })

    return {
      upload,
      item,
      field,
      isPasswordVisible,
      isPasswordConfirmationVisible,
      isAlertVisible,
      isDialogVisible,
      message,
      rules,
      submitForm,
      form,
      date,
      menu,
      picker,
      menuref,
      close,

      // icons
      icons: {
        mdiCalendar, mdiEyeOutline, mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
